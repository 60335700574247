#contact {
  background-color: rgb(33, 37, 41);

  .label {
    color: $primary;
  }

  .map-root-wrapper,
  .map-second-wrapper,
  .map {
    width: 500px;
    height: 500px;
  }

  .iconWrapper {
    width: 200;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  @media (max-width: 640px) {
    .map-root-wrapper,
    .map-second-wrapper,
    .map {
      width: 350px;
      height: 350px;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}
