body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
}

p {
  margin: 0;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.navbar-dark .navbar-nav .nav-link.active {
  color: $primary;
}

.button {
  background-color: $primary;
  width: 120px;
  font-weight: bold;
  align-self: center;
}

@media (max-width: 640px) {
  body {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.25rem;
  }

  .logo {
    width: 75px;
    height: 75px;
  }
}
