#about {
  padding-top: 60px;
  padding-bottom: 30px;

  #about-anchor {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
  }

  h1 {
    padding-bottom: 30px;
  }

  .row {
    padding: 15px 0;
  }

  .row4 {
    padding-top: 60px;
  }

  img {
    width: 500px;
  }

  @media (max-width: 640px) {
    padding-top: 45px;
    padding-bottom: 10px;

    .row {
      padding-bottom: 20px;
      p {
        padding-top: 10px;
      }
    }

    .row2 {
      display: flex;
      flex-direction: column-reverse;
    }

    .row4 {
      padding-top: 20px;
    }

    h1 {
      padding-bottom: 15px;
    }

    img {
      width: 350px;
    }
  }
}
