#reservation {
  padding-top: 120px;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &.restaurant {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../assets/smer-shishatime-background.jpeg");
  }

  &.delivery {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url("../assets/delivery-shisha-background.jpg");
    background-position-y: 120px;
  }

  .typeWrapper {
    display: flex;
    flex-direction: row;
  }

  h2 {
    padding: 15px;
    cursor: pointer;

    &.active {
      background-color: $primary;
    }
  }

  .switch-item {
    border-radius: 5px;
    margin-bottom: 30px;
  }

  form {
    min-width: 750px;
    min-height: 500px;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // option {
  //   background-color: $primary;
  //   color: white;
  // }

  @media (max-width: 640px) {
    height: auto;
    padding-top: 100px;
    padding-bottom: 20px;

    .text {
      font-size: 16px;
    }

    .typeWrapper {
      padding-top: 20px;
    }

    &.delivery {
      background-position-y: 80px;
    }

    form {
      min-width: 200px;
      min-height: 150px;
    }
  }
}
