$primary: #ff8f44;
// $theme-colors: (
// "primary": #ff8f44,
// "secondary": $secondary,
// "success": $success,
// "info": $info,
// "warning": $warning,
// "danger": $danger,
// "light": $light,
// "dark": $dark
// );

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "bootstrap";
